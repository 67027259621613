import TextTruncate from 'react-text-truncate';
import { useState } from 'react';

function Card({ text, date, avatar, username }) {
	const [line, setLine] = useState(7);

	const toggleHideText = (e) => {
		if (line === 7) {
			setLine(0);
		} else {
			setLine(7);
		}
	};

	return (
		<div className={line === 7 ? 'card' : 'card grid-active'}>
			<div className="card__header">
				<div className="card__ava">
					<img
						width="100%"
						height="100%"
						src={`../../images/avatars/${avatar}`}
						alt="Аватар"
					/>
				</div>
				<div className="card__info">
					<h4>{username}</h4>
					<span>{date}</span>
				</div>
			</div>
			<div className="card__body">
				{(text.length <= 231) ? <p>{text}</p> : <><TextTruncate line={line} element="p" truncateText="…" text={text} />
				<button
					onClick={(e) => toggleHideText(e)}
					id="cardTextMore"
					className="card__btn"
					type="button"
				>
					{line === 7 ? 'Развернуть' : 'Свернуть'}
				</button></>}
			</div>
		</div>
	);
}

export default Card;
