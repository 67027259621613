import './Hero.scss';
import Axios from 'axios';
import { useState, useEffect } from 'react';
import banner from './image_banner.svg';

function Hero({ arrlikes, onGetItems }) {
	const [action, setAction] = useState('');

	useEffect(() => {
		if (!arrlikes.alreadyLiked) {
			setAction('/api.php?action=add-like');
		} else {
			setAction('/api.php?action=remove-like');
		}
	}, [arrlikes.alreadyLiked]);

	const countHandler = () => {
		Axios.get(action, {}).then(onGetItems());
	};

	return (
		<section className="hero">
			<div className="container hero__container">
				<div className="hero__text">
					<h1>Ваше спасибо медсестре важно</h1>
					<div className="likes">
						<button onClick={countHandler} className="likes__btn" type="button">
							<svg
								className={arrlikes.alreadyLiked ? 'scale' : ''}
								width="130"
								height="156"
								viewBox="0 0 130 156"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M67.0185 84.4756C66.2048 85.0852 65.5429 85.572 65.0598 85.9238C64.598 85.5444 63.9655 85.0198 63.1885 84.3636C61.4438 82.8903 58.972 80.7548 56.0701 78.1118C50.2622 72.8218 42.751 65.5159 35.9021 57.428C29.0367 49.3206 22.9171 40.5202 19.7976 32.2405C16.6807 23.9677 16.6578 16.5315 21.4266 10.7148C26.4682 4.56658 31.673 2.05904 36.5869 1.58557C41.5507 1.10729 46.4341 2.68415 50.8167 5.11215C55.1934 7.53685 58.9648 10.7525 61.6555 13.3846C62.9967 14.6965 64.0597 15.8539 64.7849 16.6802C65.1473 17.0931 65.4248 17.4227 65.61 17.6469C65.7025 17.759 65.772 17.8446 65.8173 17.9011C65.84 17.9293 65.8567 17.9502 65.8672 17.9635L65.8784 17.9776L65.8803 17.98L65.8805 17.9803L65.8807 17.9805L66.9928 19.4004L68.1857 18.0477L68.1859 18.0475L68.1862 18.0472L68.1882 18.0449L68.2002 18.0315C68.2114 18.0189 68.2293 17.9989 68.2536 17.9721C68.3022 17.9184 68.3764 17.8369 68.4754 17.7305C68.6732 17.5175 68.9694 17.2046 69.3552 16.8136C70.1273 16.031 71.2558 14.9377 72.671 13.7062C75.5103 11.2357 79.4622 8.24565 83.9725 6.08056C88.4888 3.91254 93.4556 2.6234 98.3831 3.39064C103.261 4.15016 108.311 6.95735 112.987 13.3896C117.41 19.4752 116.955 26.8976 113.363 34.9742C109.768 43.0577 103.148 51.4859 95.8232 59.1787C88.5162 66.853 80.5933 73.708 74.488 78.6499C71.4375 81.1191 68.8458 83.1067 67.0185 84.4756Z"
									stroke="#FF4A9B"
									strokeWidth="3"
								/>
								<path
									d="M1.72821 71.6904C2.32981 74.3547 3.51031 79.4994 7.99396 85.7118C11.2271 90.1909 17.3737 96.8128 23.3916 105.468C27.7315 111.708 30.2495 115.391 31.6343 117.437C32.6181 118.892 33.2991 120.511 33.6378 122.208L23.227 153.558L47.9779 155.728C47.9779 155.728 57.3027 123.073 57.3103 109.378C57.3103 109.372 57.3103 109.368 57.3103 109.366C57.3103 107.777 57.3254 106.086 57.3651 104.279C57.5411 95.8969 59.9721 89.2939 35.8001 89.0645C35.6999 89.0645 35.6072 88.9924 35.4974 88.9545C34.5553 88.5885 33.1156 87.9305 30.9949 86.2162C30.6373 85.928 30.2627 85.6132 29.8692 85.2661C27.5101 83.1764 25.2721 81.5304 23.1665 80.2276C12.1768 73.4161 1.17957 69.2764 1.72821 71.6961V71.6904Z"
									fill="#FFAB8A"
								/>
								<path
									d="M0.00881992 56.0659C0.37016 52.1273 3.29683 39.7386 3.29683 39.7386C3.29683 39.7386 6.15917 40.3986 7.20914 42.2361C10.2777 47.614 9.27502 58.2182 9.11232 58.2808C9.10664 58.2808 14.1049 67.9084 14.9543 74.1018C14.9543 74.1075 14.9543 74.1018 14.9543 74.1018C14.9543 74.1018 28.2066 81.3116 30.9989 86.4847C31.1673 86.7995 31.3035 87.1105 31.4019 87.4082C31.7803 88.5707 31.9411 90.1617 31.9203 91.929C31.8276 98.1964 29.3568 106.671 25.664 106.019C24.754 105.856 3.42925 81.9601 2.54766 77.7522C2.34145 76.7585 -0.184147 58.1689 0.0107118 56.0659H0.00881992Z"
									fill="#FFAB8A"
								/>
								<path
									d="M34.4478 82.876C34.4478 82.876 35.0778 88.9556 28.7988 83.6231L28.2917 90.611L37.0396 95.2798L40.0079 90.1521L34.4497 82.876H34.4478Z"
									fill="#FFB092"
								/>
								<path
									d="M3.91332 45.8581C4.03251 45.6287 4.11386 45.3746 4.15358 45.1072C4.15358 45.1072 4.48844 42.7065 4.72681 40.9467C4.88383 39.7861 4.10818 38.8456 3.66738 38.6692C3.38739 38.5592 1.67339 43.0592 1.60718 47.2975C2.61363 47.3145 3.48198 46.6906 3.91332 45.8581V45.8581Z"
									fill="#FF4A9B"
								/>
								<path
									d="M14.9526 74.1021C17.8131 75.0427 35.2425 86.9212 36.1847 87.2872C38.3641 90.0463 38.7311 94.131 37.666 94.5861C36.2282 95.2043 33.0272 93.966 30.8913 91.9995C28.029 89.3675 27.1985 85.8991 26.3434 83.9667C25.9574 83.0982 23.4224 81.8353 22.4613 80.5648C19.9357 77.2272 15.278 74.3581 14.9526 74.1002V74.1021Z"
									fill="#F4A484"
								/>
								<path
									d="M48.3021 85.4492C48.3021 85.4492 25.6134 67.1365 23.1048 62.4867C23.1048 62.4867 19.1131 63.9336 20.706 70.0094C22.2989 76.0852 33.4569 86.9928 34.1229 91.2955C36.6257 107.462 45.7879 112.436 45.7879 112.436C45.7879 112.436 70.656 103.534 48.3021 85.4492V85.4492Z"
									fill="#FFAB8A"
								/>
								<path
									d="M24.5626 69.255C24.3544 69.09 24.169 68.8909 24.0177 68.6615C24.0177 68.6615 22.6745 66.5983 21.6945 65.0831C21.0475 64.0838 21.2537 62.7867 22.1864 62.0225C22.4153 61.8347 22.5515 61.7418 22.5515 61.7418C23.6526 62.6957 26.4979 64.4725 28.3935 68.3182C27.3492 69.0692 25.3193 69.8505 24.5644 69.2569L24.5626 69.255Z"
									fill="#FF4A9B"
								/>
								<path
									d="M128.271 71.9631C127.669 74.6274 126.489 79.7721 122.005 85.9845C118.772 90.4636 112.624 97.0855 106.608 105.74C102.268 111.981 99.7496 115.664 98.3667 117.71C97.3829 119.164 96.7019 120.784 96.3632 122.481L106.774 153.831L82.0231 156C82.0231 156 72.6983 123.346 72.6888 109.65C72.6888 109.645 72.6888 109.641 72.6888 109.639C72.6888 108.05 72.6737 106.358 72.634 104.551C72.458 96.1696 70.0289 89.5666 94.199 89.3372C94.2992 89.3372 94.3919 89.2651 94.5017 89.2272C95.4438 88.8612 96.8835 88.2032 99.0042 86.4889C99.3618 86.2007 99.7364 85.8859 100.13 85.5388C102.489 83.4491 104.727 81.8031 106.835 80.5003C117.824 73.6888 128.82 69.5491 128.273 71.9688L128.271 71.9631Z"
									fill="#FFAB8A"
								/>
								<path
									d="M129.991 56.0659C129.628 52.1273 126.703 39.7386 126.703 39.7386C126.703 39.7386 123.841 40.3986 122.791 42.2361C119.722 47.614 120.725 58.2182 120.887 58.2808C120.893 58.2808 115.895 67.9084 115.045 74.1018C115.045 74.1075 115.045 74.1018 115.045 74.1018C115.045 74.1018 101.793 81.3116 99.0008 86.4847C98.8305 86.7995 98.6962 87.1105 98.5978 87.4082C98.2195 88.5707 98.0568 90.1617 98.0795 91.929C98.1722 98.1964 100.643 106.671 104.336 106.019C105.246 105.856 126.57 81.9601 127.452 77.7522C127.658 76.7585 130.184 58.1689 129.989 56.0659H129.991Z"
									fill="#FFAB8A"
								/>
								<path
									d="M95.5499 82.876C95.5499 82.876 94.9199 88.9556 101.199 83.6231L101.708 90.611L92.96 95.2798L89.9917 90.1521L95.5499 82.876Z"
									fill="#FEB598"
								/>
								<path
									d="M126.084 45.8581C125.965 45.6287 125.884 45.3746 125.844 45.1072C125.844 45.1072 125.509 42.7065 125.271 40.9467C125.114 39.7861 125.889 38.8456 126.33 38.6692C126.61 38.5592 128.324 43.0592 128.39 47.2975C127.384 47.3145 126.515 46.6906 126.084 45.8581Z"
									fill="#FF4A9B"
								/>
								<path
									d="M115.045 74.1021C112.185 75.0427 94.7554 86.9212 93.8133 87.2872C91.6339 90.0463 91.2669 94.131 92.332 94.5861C93.7698 95.2043 96.9708 93.966 99.1066 91.9995C101.969 89.3675 102.799 85.8991 103.655 83.9667C104.041 83.0982 106.576 81.8353 107.537 80.5648C110.062 77.2272 114.718 74.3581 115.045 74.1002V74.1021Z"
									fill="#F4A484"
								/>
								<path
									d="M81.6963 85.4497C81.6963 85.4497 104.385 67.1369 106.894 62.4872C106.894 62.4872 110.885 63.9341 109.292 70.0099C107.7 76.0857 96.5415 86.9933 95.8756 91.296C93.3727 107.462 84.2106 112.436 84.2106 112.436C84.2106 112.436 59.3424 103.535 81.6944 85.4497H81.6963Z"
									fill="#FFAB8A"
								/>
								<path
									d="M105.435 69.255C105.643 69.09 105.828 68.8909 105.979 68.6615C105.979 68.6615 107.323 66.5983 108.303 65.0831C108.95 64.0838 108.745 62.7867 107.811 62.0225C107.582 61.8347 107.446 61.7418 107.446 61.7418C106.345 62.6957 103.329 64.6337 101.435 68.4775C102.347 68.8796 104.678 69.8486 105.435 69.255Z"
									fill="#FF4A9B"
								/>
							</svg>
						</button>
						<span className="likes__counter">Нравится: {arrlikes.likes}</span>
					</div>
				</div>
				<div className="hero__img">
					<img width="100%" height="100%" src={banner} alt="анлайк" />
				</div>
			</div>
		</section>
	);
}

export default Hero;
