import './About.scss';

function About() {
	return (
		<section id="about" className="about">
			<div className="container about__container">
				<h3 className="about__title">О проекте</h3>
				<div className="about__info">
					<p>
						Медперсонал является важным звеном в лечебном процессе. Перед этими
						людьми стоит много сложных задач, часть которых скрыта от глаз
						пациентов. Медицинская сестра или медицинский брат — это правая рука
						врача. Они выполняют сложные процедуры и манипуляции, забирают
						анализы, готовят к операции и многое другое. И зачастую больше всего
						времени с пациентами проводят именно медсестры и медбратья.
					</p>
					<p>
						<b>
							Цель проекта — сформировать зону опоры и поддержки для одной из
							самых выгорающих профессий в медицине.
						</b>
					</p>
				</div>
			</div>
		</section>
	);
}

export default About;
