import './Header.scss';
import { Link } from 'react-scroll';
import { useState } from 'react';
import logo from './logo-blue.svg';

function Header() {
    const [menuActive, setMenuActive] = useState(false);

    return (
        <header>
            <nav>
                <div className="container header__container">
                    <div className="navbar">
                        <ul className={menuActive ? 'menu active' : 'menu'}>
                            <li className="menu__item">
                                <Link
                                    to="about"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    className="menu__item-link"
                                    onClick={() => setMenuActive(false)}
                                >
                                    О проекте
                                </Link>
                            </li>
                            <li className="menu__item">
                                <Link
                                    to="gratitude"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    className="menu__item-link"
                                    onClick={() => setMenuActive(false)}
                                >
                                    Оставить отзыв
                                </Link>
                            </li>
                            <li className="menu__item">
                                <Link
                                    to="reviews"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    className="menu__item-link"
                                    onClick={() => setMenuActive(false)}
                                >
                                    Отзывы
                                </Link>
                            </li>
                            <div className="logo-navbar">
                                <img src={logo} alt="logo" />
                            </div>
                        </ul>
                        <div className="logo-header">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className={menuActive ? 'burger active-burger' : ' burger'} onClick={() => setMenuActive(!menuActive)}>
                            <span></span>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
