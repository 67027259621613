import './Reviews.scss';
import { useState } from 'react';

import Card from '../Card/Card';

function Reviews({ reviews }) {
	const [visible, setVisible] = useState(9);
	const showMoreItems = () => {
		setVisible((prevValue) => prevValue + 3);
	};

	const hideMoreItems = () => {
		setVisible(9);
	};

	return (
		<section id="reviews" className="reviews">
			<div className="container">
				<h3 className="reviews__title">Отзывы</h3>
				<div className="reviews__grid">
					{reviews.slice(0, visible).map((rev) => {
						return (
							<Card
								text={rev.text}
								date={rev.date}
								avatar={rev.avatar}
								username={rev.name}
								key={rev.uid}
							/>
						);
					})}
				</div>
				{reviews.length > 9 ? (
					<>
						{visible >= reviews.length ? (
							<button
								onClick={hideMoreItems}
								className="reviews__btn"
								type="button"
							>
								Скрыть
							</button>
						) : (
							<button
								onClick={showMoreItems}
								className="reviews__btn"
								type="button"
							>
								Развернуть
							</button>
						)}
					</>
				) : (
					<></>
				)}
			</div>
		</section>
	);
}

export default Reviews;
