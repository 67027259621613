import './Modal.scss';
import sucs from './bi_check-circle2.svg';
import fail from './bi_check-circle.svg';

function Modal({ isOpen, isClose, badRequest }) {
	return (
		<>
			<div className={isOpen ? 'modal__wrapper active' : 'modal__wrapper'}>
				<div className="container">
					<div className="modal">
						<img className="modal__img" src={badRequest ? fail : sucs} alt="icon" />
						<h4 className="modal__title">
							{badRequest ? 'Что-то пошло не так, попробуйте позже' : 'Спасибо! Ваш отзыв успешно отправлен'}
						</h4>
						<button onClick={isClose} type="button" className="modal__btn">
							Вернуться
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

export default Modal;
