import { useEffect, useState } from 'react';
import Axios from 'axios';
import './App.scss';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Gratitude from './components/Gratitude/Gratitude';
import ReviewsCounter from './components/ReviewsCounter/ReviewsCounter';
import Reviews from './components/Reviews/Reviews';
import About from './components/About/About';
import Footer from './components/Footer/Footer.jsx';
import Modal from './components/Modal/Modal';

// import Modal from './components/Modal/Modal';

function App() {
	const [items, setItems] = useState([]);
	const [likes, setLikes] = useState([]);
	const [modalisOpen, setModalIsOpen] = useState(false);
	const [badRequest, setBadRequest] = useState(false);

	const onBadRequest = () => {
		setBadRequest(true);
	};

	const onOpenModal = () => {
		setModalIsOpen(true);
	};

	const onCloseModal = () => {
		setModalIsOpen(false);
	};

	const url = '/api.php?action=get';

	const getItems = async () => {
		const response = await Axios.get(url);
		setItems(response.data.reviews);
		setLikes(response.data);
	};

	useEffect(() => {
		getItems();
	}, []);

	return (
		<div className="App">
			<Header />
			<main>
				<Hero arrlikes={likes} onGetItems={getItems} />
				<Gratitude
					onBadRequest={onBadRequest}
					onOpenModal={onOpenModal}
					onGetItems={getItems}
				/>
				<ReviewsCounter reviewsSum={items.length} />
				<Reviews reviews={items} />
				<About />
				<Modal
					badRequest={badRequest}
					isOpen={modalisOpen}
					isClose={onCloseModal}
				/>
			</main>
			<Footer />
		</div>
	);
}

export default App;
