import './Footer.scss';
import { Link } from 'react-scroll';
import logo from './logo-white.svg';

function Footer() {
    return (
        <footer>
            <div className="container footer-container">
                <ul className="footer-menu">
                    <li className="footer-menu__item">
                        <Link to="about" spy={true} smooth={true} duration={500} className="footer-menu__link">
                            О проекте
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link to="gratitude" spy={true} smooth={true} duration={500} className="footer-menu__link">
                            Оставить отзыв
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link to="reviews" spy={true} smooth={true} duration={500} className="footer-menu__link">
                            Отзывы
                        </Link>
                    </li>
                </ul>
                <div className="logo-footer">
                    <img src={logo} alt="logo" />
                </div>
            </div>
        </footer>
    );
}

export default Footer;
