import Axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import Reaptcha from 'reaptcha';
import './Gratitude.scss';

function Gratitude({ onGetItems, onOpenModal, onBadRequest }) {
	const [valid, setValid] = useState(false);
	const [name, setName] = useState('');
	const [review, setReview] = useState('');
	const [nameDirty, setNameDirty] = useState(false);
	const [reviewDirty, setReviewDirty] = useState(false);
	const [nameError, setNameError] = useState('Имя не может быть пустым');
	const [reviewError, setReviewError] = useState('Отзыв не может быть пустым');
	const [captchaToken, setCaptchaToken] = useState(null);
	const captchaRef = useRef(null);

	const url = '/api.php?action=add-review';

	function submit(e) {
		e.preventDefault();
		Axios.post(url, {
			name,
			text: review,
		})
			.then(() => {
				setName('');
				setReview('');
				window.grecaptcha.reset();
				onGetItems();
				onOpenModal();
			})
			.catch(() => {
				onBadRequest();
				onOpenModal();
			});
	}

	const verify = () => {
		captchaRef.current.getResponse().then((res) => {
			setCaptchaToken(res);
		});
	};

	useEffect(() => {
		if (nameError || reviewError || !captchaToken) {
			setValid(false);
		} else {
			setValid(true);
		}
	}, [nameError, reviewError, captchaToken]);

	const nameHandler = (e) => {
		setName(e.target.value);
		if (name.length < 1) {
			setNameError('Введите корректное имя');
		} else {
			setNameError('');
		}
	};

	const reviewHandler = (e) => {
		setReview(e.target.value);
		if (review.length <= 10) {
			setReviewError('Минимальное кол-во символов 10');
		} else if (review.length >= 600) {
			setReviewError('Максимальное кол-во символов 600');
		} else {
			setReviewError('');
		}
	};

	const blurHandler = (e) => {
		// eslint-disable-next-line default-case
		switch (e.target.name) {
			case 'username':
				setNameDirty(true);
				break;
			case 'review':
				setReviewDirty(true);
				break;
		}
	};

	return (
		<section id="gratitude" className="gratitude">
			<div className="container gratitude__container">
				<h3 className="gratitude__title">Оставить благодарность</h3>
				<form onSubmit={(e) => submit(e)} className="form" action="POST">
					<label
						htmlFor="username"
						style={
							nameDirty && nameError ? { color: 'red' } : { color: '#8775AE' }
						}
					>
						{nameDirty && nameError ? nameError : 'Как вас зовут?'}
						<input
							className="form__name"
							id="username"
							type="text"
							name="username"
							placeholder="Ваше имя"
							value={name}
							maxLength="15"
							onInput={(e) => nameHandler(e)}
							onBlur={(e) => blurHandler(e)}
						/>
					</label>
					<label
						htmlFor="username"
						style={
							reviewDirty && reviewError
								? { color: 'red' }
								: { color: '#8775AE' }
						}
					>
						{reviewDirty && reviewError ? reviewError : 'Ваш отзыв'}
						<textarea
							className="form__textarea"
							name="review"
							id="textarea"
							cols="30"
							rows="10"
							minLength="10"
							maxLength="601"
							placeholder="Напишите ваш отзыв ..."
							value={review}
							onInput={(e) => reviewHandler(e)}
							onBlur={(e) => blurHandler(e)}
						></textarea>
					</label>
					<Reaptcha
						sitekey={process.env.REACT_APP_SITE_KEY}
						ref={captchaRef}
						onVerify={verify}
					/>
					<button disabled={!valid} className="form__btn" type="submit">
						Отправить
					</button>
				</form>
			</div>
		</section>
	);
}

export default Gratitude;
