import './ReviewsCounter.scss';

function ReviewsCounter({reviewsSum}) {
	let array = [...reviewsSum.toString()].map(Number);
	return (
		<section className="reviews-counter">
			<div className="container reviews-counter__container">
				<h3 className="reviews-counter__title">Оставлено отзывов</h3>
				<div className="reviews-counts">
					<div className="reviews-counts__item">{array.length < 4 ? 0 : array[array.length-4]}</div>
					<div className="reviews-counts__item">{array.length < 3 ? 0 : array[array.length-3]}</div>
					<div className="reviews-counts__item">{array.length < 2 ? 0 : array[array.length-2]}</div>
					<div className="reviews-counts__item">{array[array.length-1]}</div>
				</div>
			</div>
		</section>
	);
}

export default ReviewsCounter;
